import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/fflp_de.module.scss"
import Data from "./data/fflp_de.json"
import FooterLinks from "src/components/footerlinks_language/footer.js"


class fflp extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: Data,
      language: props.language
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }
      this.setState({ language: this.props.language});
      if (typeof window != "undefined") {
        window.mapParams();
      }
    });
  }

  render() {
    return (
      <>
      <div id = {Styles.fflp} style={{ display: this.state.display }}>
        <div className = {Styles.header}><img src={this.state.data.topLogo} alt="" /></div>
     <div className = {Styles.main}>
       <h1 className = "hc_param">{this.state.data.headline}</h1>

       <div className = {Styles.stepGrid}>
         <div className = {Styles.step}>1</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead1}</h2>
           <p>{this.state.data.copy1}</p>
         </div>

         <div className = {Styles.step}>2</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead2}</h2>
           <p>{this.state.data.copy2}</p>
         </div>
       </div>
       <div className = {Styles.comp}>{this.state.data.compliance1}</div>
       <div className = {Styles.comp}>{this.state.data.compliance2}</div>
       <button className = {[Styles.download, Styles.cl1].join(' ')} data-cy="cta" onClick = {() => window.triggerInstall()}>{this.state.data.button}</button>
       {this.props.children}
       </div>
        </div>
      <FooterLinks language = "it"></FooterLinks>
      </>
    )
  }
}
export default fflp
