import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Fflpit from "src/components/fflp_it"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import CtaDisclosureInt from "src/components/ctadisclosureint"

const fflpData = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       body {
         height: 100vh;
         width: 100%;
         min-width: 728px;
         min-height: 700px;
         background: #fff;
         padding: 0;
         margin: 0;
         background-repeat: no-repeat;
         background-size: cover;
         font-family: 'Roboto', sans-serif;
       }

       .btf_cb-module--btfCb button.btf_cb-module--install {
          border: none;
          background: #0078D4;
          color: #fff;
          font-weight: 500;
          font-size: 20pt;
          padding: 15px 50px;
          border-radius: 50px;
          cursor: pointer;
          letter-spacing: .5px;
          transition: .2s ease;
          margin: 25px auto;
          max-width: 300px;
       }

       #fflp-module--fflp {
         height: 100vh;
       }

       #fflp-module--fflp .fflp-module--main {
         margin: 100px auto 0;
       }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Easy View</title></Helmet>
    <section>
      <Fflpit data={fflpData}>
        <CtaDisclosureInt data={fflpData} language='it'></CtaDisclosureInt>
      </Fflpit>
      </section>
    </HomepageLayout>
  )
}
